import { ComputedRef, defineComponent, onMounted, ref, watch } from 'vue';
import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox/BaseCheckbox.vue';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { InventionFilter } from '@/store/modules/models/state';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';

export default defineComponent({
  name: 'InventionFilters',

  components: {
    BaseCheckbox
  },

  setup() {
    const invention = ref(false);
    const utility = ref(false);
    const { state, dispatch } = useStore();

    const switchType = () => {
      switch (state.models.filters.inventionFilter) {
        case (InventionFilter.UTILITY):
          utility.value = true;
          invention.value = false;
          break;
        case (InventionFilter.INVENTION):
          utility.value = false;
          invention.value = true;
          break;
        case (InventionFilter.INVENTION_UTILITY):
          utility.value = true;
          invention.value = true;
          break;
        case (InventionFilter.NOTHING):
          utility.value = false;
          invention.value = false;
          break;
      }
    };

    watch(() => state.models.filters.inventionFilter, () => {
      switchType();
    });

    onMounted(() => {
      switchType();
    });

    const changeFilter = ({ checked, value }: { checked: ComputedRef<boolean>; value: string }) => {
      let newValue = InventionFilter.INVENTION_UTILITY;
      if (+value === InventionFilter.INVENTION) {
        invention.value = !checked.value;
      }
      if (+value === InventionFilter.UTILITY) {
        utility.value = !checked.value;
      }
      if (invention.value && utility.value) {
        newValue = InventionFilter.INVENTION_UTILITY;
      }
      if (invention.value && !utility.value) {
        newValue = InventionFilter.INVENTION;
      }
      if (!invention.value && utility.value) {
        newValue = InventionFilter.UTILITY;
      }
      if (!invention.value && !utility.value) {
        newValue = InventionFilter.NOTHING;
      }
      dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.SET_FILTERS}`, {
        ...state.models.filters,
        inventionFilter: newValue
      });
    };

    return {
      changeFilter,
      InventionFilter,
      invention,
      utility
    };
  }
});
