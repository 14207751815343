<template>
  <BasePageList
    page-name="Изобретения и полезные модели"
    :showed-model-types="modelTypes"
  >
    <template v-slot:filter-slot>
      <InventionFilters />
    </template>
  </BasePageList>
</template>

<script src="./PageInventionList.ts" lang="ts"/>
<style src="./PageInventionList.scss" lang="scss"/>
