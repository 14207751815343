<template>
  <div class="invention-filter-wrapper">
    <BaseCheckbox
      name="utility"
      label="Изобретение"
      value="1"
      :is-checked="invention"
      @clicked="changeFilter($event)"
    />
    <BaseCheckbox
      name="invention"
      label="Полезная модель"
      value="2"
      :is-checked="utility"
      @clicked="changeFilter($event)"
    />
  </div>
</template>

<script src="./InventionFilters.ts" lang="ts"/>
<style src="./InventionFilters.scss" lang="scss"/>
