import { computed, defineComponent } from 'vue';
import InventionFilters from '@/components/Model/PageInventionList/components/InventionFilters/InventionFilters.vue';
import BasePageList from '@/components/Model/BasePageList/BasePageList.vue';
import { RegisteredModelTypes } from '@/types/modelType';
import { useStore } from 'vuex';
import { InventionFilter } from '@/store/modules/models/state';

export default defineComponent({
  name: 'PageInventionList',

  components: {
    InventionFilters,
    BasePageList
  },

  setup() {
    const { state } = useStore();
    const modelTypes = computed(() => {
      switch (state.models.filters.inventionFilter) {
        case InventionFilter.INVENTION_UTILITY:
          return [RegisteredModelTypes.UTILITY_MODEL, RegisteredModelTypes.INVENTION];
        case InventionFilter.INVENTION:
          return [RegisteredModelTypes.INVENTION];
        case InventionFilter.UTILITY:
          return [RegisteredModelTypes.UTILITY_MODEL];
        case InventionFilter.NOTHING:
          return [];
        default:
          return [];
      }
    });
    return { modelTypes };
  }
});
